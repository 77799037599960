var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"call-bottom pt-2",class:{'justify-content-center border-top border-2': !_vm.minimized}},[_c('div',{staticClass:"call-btns"},[_c('div',{staticClass:"d-flex grouped-btns mr-2 centralize"},[_c('div',{staticClass:"call-btn",class:[
          _vm.webcamState === 'on' ? 'bg-primary call-btn-on' : '',
          { disabled: _vm.me.webcamInProgress || _vm.me.shareInProgress }
        ],on:{"click":_vm.toggleWebcam}},[_c('feather-icon',{staticClass:"cursor-pointer text-white",attrs:{"icon":_vm.webcamState === 'on' ? 'VideoIcon' : 'VideoOffIcon',"size":"21"}})],1),_c('div',{staticClass:"call-btn",class:_vm.micState === 'on' ? 'bg-primary call-btn-on' : '',on:{"click":_vm.toggleMicState}},[_c('feather-icon',{staticClass:"cursor-pointer text-white",attrs:{"icon":_vm.micState === 'on' ? 'MicIcon' : 'MicOffIcon',"size":"21"}})],1)]),_c('div',{staticClass:"text-white"},[_c('div',{staticClass:"call-btn call-btn-on",class:[
          _vm.shareState === 'on' ? 'bg-danger' : 'bg-primary',
          { disabled: _vm.me.shareInProgress || _vm.me.webcamInProgress }
        ],on:{"click":_vm.toggleShare}},[_c('feather-icon',{staticClass:"cursor-pointer text-white",attrs:{"icon":_vm.shareState === 'on' ? 'XSquareIcon' : 'CastIcon',"size":"21"}})],1),_c('p',{staticClass:"mb-0"},[_vm._v(" Share ")])]),(!_vm.minimized)?_c('div',{staticClass:"mr-2 text-center text-white"},[_c('b-badge',{directives:[{name:"show",rawName:"v-show",value:(_vm.unseenMsgCount),expression:"unseenMsgCount"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('New Message'),expression:"'New Message'",modifiers:{"hover":true}}],ref:"messageBadge",staticClass:"float-right",attrs:{"variant":"primary","pill":""}},[_vm._v(" "+_vm._s(_vm.unseenMsgCount)+" ")]),_c('div',{staticClass:"call-btn call-btn-on mx-0",class:_vm.showChat ? 'bg-wz-teal' : 'bg-primary',on:{"click":() => { _vm.$emit('toggle-chat'); _vm.unseenMsgCount = 0 }}},[_c('feather-icon',{staticClass:"cursor-pointer text-white",attrs:{"icon":"MessageSquareIcon","size":"21"}})],1),_c('p',{staticClass:"mb-0"},[_vm._v(" Chat ")])],1):_vm._e(),_c('div',{staticClass:"call-btn call-btn-on bg-danger ml-1",on:{"click":_vm.endCall}},[_c('feather-icon',{staticClass:"cursor-pointer text-white",attrs:{"icon":"PhoneIcon","size":"21"}})],1),_c('div',{staticClass:"minimize-btn d-flex flex-column align-items-center text-white"},[_c('div',{staticClass:"call-btn call-btn-on bg-primary mr-0",on:{"click":() =>_vm.$emit('toggle-call-view')}},[_c('feather-icon',{staticClass:"cursor-pointer text-white",attrs:{"icon":_vm.minimized ? 'MinimizeIcon' : 'Minimize2Icon',"size":"21"}})],1),_c('p',{staticClass:"mb-0"},[_vm._v(" Minimize ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }