<template>
  <component
    :is="tag"
    class="align-items-center"
    v-on="$listeners"
  >
    <b-avatar
      size="42"
      :src="getAvatar(user.avatar, 1)"
      :badge="isChatContact"
      :text="getInitials(user.name)"
      class="badge-minimal"
      :badge-variant="resolveAvatarBadgeVariant(user.status)"
      :variant="user.type === 'group' ? 'wz-teal' : 'primary'"
    >
      <i
        v-if="user.type === 'group'"
        class="fas fa-user-friends"
        :style="'font-size: 18px;'"
      />
    </b-avatar>
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0 text-truncate">
        {{ user.name }}
      </h5>
      <p class="card-text text-truncate">
        <span v-if="isChatContact">
          <span v-if="messageType">
            <span v-if="messageType === 'text'">{{ user.chat.lastMessage.message }}</span>
            <!-- Media Call -->
            <div v-else-if="messageType === 'mediacall'">
              <feather-icon
                :icon="callInfo.icon"
                :class="callInfo.sidebarIconColorClass"
              />
            &nbsp;
              <span>{{ callInfo.text }}</span>
            </div>
            <!-- Group Action -->
            <div v-else-if="messageType === 'groupaction'">
              {{ getGroupActionMessage }}
            </div>
            <!-- Attachment -->
            <span v-else>
              <i
                class="fas"
                :class="user.chat.lastMessage.messageType === 'ATTACHMENT' ? GET_FILE_ICON(messageType) : ''"
              />
              <span>&nbsp;{{ CAPITALIZE(messageType) }} File</span>
            </span>
          </span>
          <span v-else>{{ user.participants.length + 1 }} Participants</span>
        </span>
        <span
          v-else-if="user.projectRoles"
          class="text-capitalize"
        >{{ user.projectRoles.data[0].role.name }}
        </span>
        <span v-else-if="user.type === 'group'">
          {{ user.participants.length + 1 }} Participants
        </span>
      </p>
    </div>
    <div
      v-if="isChatContact"
      class="chat-meta text-nowrap"
    >
      <b-button
        v-if="ongoingCall === user.roomUid"
        variant="success"
        size="sm"
        @click="joinMediaCall"
      >
        <feather-icon icon="PhoneCallIcon" />
        Join
      </b-button>
      <div v-else>
        <small
          v-if="messageType"
          class="float-right chat-time"
        >{{ formatDateToMonthShort(user.chat.lastMessage.time, { hour: 'numeric', minute: 'numeric' }) }}</small>
        <b-badge
          v-if="user.chat.unseenMsgs"
          pill
          variant="primary"
          class="float-right"
        >
          {{ user.chat.unseenMsgs }}
        </b-badge>
      </div>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge, BButton } from 'bootstrap-vue'
import { formatDateToMonthShort } from '@core/utils/filter'
import { getUserData } from '@/auth/utils'
import { computed, inject, getCurrentInstance } from 'vue'
import EventBus from '@/event-bus'
import useChat from './useChat'

export default {
  components: {
    BAvatar,
    BBadge,
    BButton,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      default: false,
    },
    contacts: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const store = getCurrentInstance().proxy.$root.$store
    const {
      resolveAvatarBadgeVariant, GET_CALL_INFO, GET_MSG_TYPE, GET_FILE_ICON,
    } = useChat()

    const ongoingCall = computed(() => store.state.project.ongoingCall)
    const projectUsers = computed(() => store.state.project.projectUsers)
    const chatService = inject('$chatService')

    const messageType = computed(() => {
      if (props.isChatContact && props.user && props.user.chat.lastMessage?.messageType) {
        return GET_MSG_TYPE(props.user.chat.lastMessage.messageType, props.user.chat.lastMessage.message)
      }
      return null
    })

    const callInfo = computed(() => {
      if (props.user.chat && props.user.chat.lastMessage.messageType === 'MEDIACALL') {
        return GET_CALL_INFO(JSON.parse(props.user.chat.lastMessage.message), getUserData().uuid)
      }
      return null
    })

    const getGroupActionMessage = computed(() => {
      if (props.user?.chat) {
        const data = JSON.parse(props.user.chat.lastMessage.message)
        let { peerUid } = data
        if (!Array.isArray(peerUid)) {
          peerUid = [peerUid]
        }
        const users = peerUid.map(userUid => projectUsers.value.find(user => user.uuid === userUid)?.name.split(' ')[0])

        let subject = ''
        if (users.length === 1) subject = `${users[0]} has`
        if (users.length === 2) subject = `${users.slice(0, 2).map(username => username.split(' ')[0]).join(' and ')} have`
        if (users.length > 2) subject = `${users.slice(0, 2).map(username => username.split(' ')[0]).join(', ')} and ${users.length - 2} other${users.length - 2 > 1 ? 's' : ''} have`

        let action = ''
        switch (data.type) {
          case 'KICK':
            action = 'been removed'
            break
          case 'LEFT':
            action = 'left'
            break
          case 'ADD':
            action = 'been added'
            break
          case 'RENAME':
            action = 'renamed'
            subject = 'Conversation'
            break
          default:
            return ''
        }

        return `${subject} ${action}`
      }
      return ''
    })

    const joinMediaCall = async () => {
      const contact = props.contacts.find(conversation => conversation.roomUid === ongoingCall.value)
      chatService.joinMediaRoom(ongoingCall.value, 'audio')
      const _client = await chatService.activeMediaRoom()
      EventBus.$emit('incoming', contact, _client)
      EventBus.$emit('user-on-call')
    }

    return {
      formatDateToMonthShort,
      resolveAvatarBadgeVariant,
      joinMediaCall,
      messageType,
      callInfo,
      getGroupActionMessage,
      ongoingCall,

      GET_FILE_ICON,
    }
  },
}
</script>

<style lang="scss" scoped>
.chat-time {
  margin-top: 5px;
}
.floating-chat {
  .chat-meta {
    margin-right: 16px;
  }
}
</style>
