<template>
  <div>
    <b-button
      id="stickers"
      v-b-tooltip.hover="'Stickers'"
      variant="primary"
      class="p-0 mr-1 rounded-circle action-btn"
      @click="showStickersContainer = !showStickersContainer"
    >
      <feather-icon icon="TwitchIcon" />
    </b-button>

    <sticker-container
      v-if="showStickersContainer"
      v-on-clickaway="() => { showStickersContainer = false }"
      :active-contact="activeContact"
      @sticker-sent="onStickerSent"
    />
  </div>
</template>

<script>
import { getCurrentInstance, reactive, toRefs } from 'vue'
import { BButton } from 'bootstrap-vue'
import { mixin as clickaway } from 'vue-clickaway'
import StickerContainer from './StickerContainer.vue'

export default {
  components: {
    BButton,
    StickerContainer,
  },
  mixins: [clickaway],
  props: {
    activeContact: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const root = getCurrentInstance().proxy.$root
    const state = reactive({
      showStickersContainer: false,
    })

    const onStickerSent = payload => {
      state.showStickersContainer = false
      root.$emit('sticker-sent', payload)
    }

    return {
      ...toRefs(state),
      onStickerSent,
    }
  },
}
</script>
