// eslint-disable-next-line import/no-cycle
import apolloProvider from '@/libs/vue-apollo'
import mutation from './mutation'
import queries from './queries'

export default {
  getMyusers(data) {
    return apolloProvider.query({
      query: queries.COMPANY_OWNER,
      variables: {
        ...data,
      },
    })
  },
  getCompanies(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.COMPANIES,
      variables: {
        first: 20,
        ...data,
      },
    })
  },
  getCompanyPaymentGateways(data) {
    return apolloProvider.query({
      query: queries.GET_COMPANY_PAYMENT_GATEWAYS,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },
  updateDefaultBillingInfo(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_DEFAULT_BILLING_INFO,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },
  createCompany(data) {
    return apolloProvider.mutate({
      mutation: mutation.CREATE_COMPANY,
      variables: {
        ...data,
      },
    })
  },
  getCompany(id, withProject = false) {
    return apolloProvider.query({
      query: withProject ? queries.GET_COMPANY : queries.EDIT_COMPANY,
      variables: {
        id,
      },
    })
  },
  updateCompany(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_COMPANY,
      variables: {
        ...data,
      },
    })
  },
  getCompanyUser(id) {
    return apolloProvider.query({
      query: queries.COMPANY_USER,
      variables: {
        uuid: id,
      },
    })
  },

  deleteCompany(id) {
    return apolloProvider.mutate({
      mutation: mutation.DELETE_COMPANY,
      variables: {
        id,
      },
    })
  },

  getUserRoles() {
    return apolloProvider.query({
      query: queries.COMPANY_ROLES,
      variables: {},
    })
  },

  getAllRoles() {
    return apolloProvider.query({
      query: queries.GET_ALL_ROLES,
      variables: {},
    })
  },

  getAllCompanies() {
    return apolloProvider.query({
      query: queries.GET_ALL_COMPANY,
      variables: {},
    })
  },

  searchCompanies(data) {
    return apolloProvider.query({
      query: queries.SEARCH_COMPANIES,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },

  searchProjects(data) {
    return apolloProvider.query({
      query: queries.SEARCH_PROJECTS,
      variables: {
        ...data,
      },
    })
  },

  getTimeZone({ keyword = '' } = {}) {
    return apolloProvider.query({
      query: queries.GET_TIMEZONE,
      variables: {
        keyword,
      },
    })
  },

  getMyCompanyInfo(uuid) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_MY_COMPANY,
      variables: { uuid },
    })
  },

  getCompanyPaymentSettings(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_COMPANY_PAYMENT_SETTINGS,
      variables: { ...data },
    })
  },

  updateCompanyTimezone(variables) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_REPORT_TIMEZONE,
      variables,
    })
  },

  getMyCompanyTimeZone(variables) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_MY_COMPANY_TIMEZONE,
      variables: {
        ...variables,
      },
    })
  },

  uploadApplication(input, file) {
    return apolloProvider.mutate({
      mutation: mutation.UPLOAD_APPLICATION,
      context: {
        hasUpload: true, // Important!
      },
      variables: {
        input,
        file,
      },
    })
  },
  getApplication(variables) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_APPLICATION,
      variables: {
        ...variables,
      },
    })
  },

  getSpecificApplication(variables) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_APPLICATION_SPECIFIC,
      variables: {
        ...variables,
      },
    })
  },

  getApplicationBuild(variables) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_APPLICATION_BUILD,
      variables: {
        ...variables,
      },
    })
  },

  getApplicationBuildByVersion(variables) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_APPLICATION_BUILD_BY_VERSION,
      variables: {
        ...variables,
      },
    })
  },

  getCountries() {
    return apolloProvider.query({
      query: queries.GET_COUNTRIES,
      variables: {},
    })
  },
  updateAirwallexBillingInformation(variables) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_AIRWALLEX_BILLING_INFORMATION,
      fetchPolicy: 'no-cache',
      variables: {
        ...variables,
      },
    })
  },
  updateStripeBillingInformation(variables) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_STRIPE_BILLING_INFORMATION,
      fetchPolicy: 'no-cache',
      variables: {
        ...variables,
      },
    })
  },
  getCompanyLeads(data) {
    return apolloProvider.query({
      fetchPolicy: 'no-cache',
      query: queries.GET_COMPANY_LEADS,
      variables: {
        ...data,
      },
    })
  },
  createCompanyLead(variables) {
    return apolloProvider.mutate({
      mutation: mutation.CREATE_COMPANY_LEAD,
      fetchPolicy: 'no-cache',
      variables: {
        ...variables,
      },
    })
  },
  updateCompanyLead(variables) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_COMPANY_LEAD,
      fetchPolicy: 'no-cache',
      variables: {
        ...variables,
      },
    })
  },
  updateCompanyLeadStatus(variables) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_COMPANY_LEAD_STATUS,
      variables: {
        ...variables,
      },
    })
  },
  sendEmailForLead(variables) {
    return apolloProvider.mutate({
      mutation: mutation.SEND_EMAIL_FOR_LEAD,
      variables: {
        ...variables,
      },
    })
  },
  finalizeLead(variables) {
    return apolloProvider.mutate(
      {
        mutation: mutation.FINALIZE_LEADS,
        variables: {
          ...variables,
        },
      },
    )
  },
  getCompanyWallet(data) {
    return apolloProvider.query({
      query: queries.GET_COMPANY_WALLET,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },
  configCompanyWallet(data) {
    return apolloProvider.mutate({
      mutation: mutation.CONFIG_COMPANY_WALLET,
      variables: {
        ...data,
      },
    })
  },
  makeCompanyWalletDeposit(data) {
    return apolloProvider.mutate({
      mutation: mutation.MAKE_COMPANY_WALLET_DEPOSIT,
      variables: {
        ...data,
      },
    })
  },

  batchImportLead(variables) {
    return apolloProvider.mutate({
      mutation: mutation.IMPORT_EXCEL,
      variables: {
        ...variables,
      },
    })
  },

  getCompanyTransactions(data) {
    return apolloProvider.query({
      query: queries.GET_COMPANY_TRANSACTIONS,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },

  getCompanyWalletTransactions(data) {
    return apolloProvider.query({
      query: queries.GET_COMPANY_WALLET_TRANSACTIONS,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },
  getCompanyShareTransactions(data) {
    return apolloProvider.query({
      query: queries.GET_COMPANY_SHARE_TRANSACTIONS,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },

  getCompanyVendors(data) {
    return apolloProvider.query({
      query: queries.GET_COMPANY_VENDORS,
      fetchPolicy: 'no-cache',
      variables: { ...data },
    })
  },

  splitCompanyWallet(data) {
    return apolloProvider.mutate({
      mutation: mutation.SPLIT_COMPANY_WALLET,
      variables: {
        ...data,
      },
    })
  },
  mergeCompanyWallets(data) {
    return apolloProvider.mutate({
      mutation: mutation.MERGE_COMPANY_WALLETS,
      variables: {
        ...data,
      },
    })
  },
  updateCompanyWalletShareDetails(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_COMPANY_WALLET,
      variables: {
        ...data,
      },
    })
  },
  getCompanyBillingInfo(data) {
    return apolloProvider.query({
      query: queries.GET_COMPANY_BILLING_INFO,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },
  postBillingSetting(data, billingType = 'swift') {
    let mutationEndpoint = mutation.SAVE_BILLING_SETTINGS
    if (billingType === 'ach') {
      mutationEndpoint = mutation.SAVE_ACH_BILLING_SETTINGS
    } else if (billingType === 'fx') {
      mutationEndpoint = mutation.SAVE_FX_BILLING_SETTINGS
    }

    return apolloProvider.mutate({
      mutation: mutationEndpoint,
      variables: {
        ...data,
      },
    })
  },
  attachBankToWallet(data) {
    return apolloProvider.mutate({
      mutation: mutation.ATTACH_BANK_TO_INTERNAL_WALLET,
      variables: {
        ...data,
      },
    })
  },
  withdrawFromCompanyBankWallet(data) {
    return apolloProvider.mutate({
      mutation: mutation.WITHDRAW_FROM_COMPANY_BANK_WALLET,
      variables: {
        ...data,
      },
    })
  },
  makeCompanyInternalWalletTransfer(data) {
    return apolloProvider.mutate({
      mutation: mutation.COMPANY_INTERNAL_WALLET_TRANSFER,
      variables: {
        ...data,
      },
    })
  },
  updateBillingCharges(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_BILLING_CHARGES,
      variables: {
        ...data,
      },
    })
  },
  getBillingCharges(data) {
    return apolloProvider.query({
      query: queries.GET_BILLING_CHARGES,
      fetchPolicy: 'no-cache',
      variables: {
        ...data,
      },
    })
  },
  stripeDepositCompanyWallet(data) {
    return apolloProvider.mutate({
      mutation: mutation.STRIPE_DEPOSIT_COMPANY_WALLET,
      variables: {
        ...data,
      },
    })
  },
  verifyCompanyWalletDepositByStripe(data) {
    return apolloProvider.mutate({
      mutation: mutation.VERIFY_STRIPE_COMPANY_WALLET_DEPOSIT,
      variables: {
        ...data,
      },
    })
  },
  getCompanyWalletsCount(data) {
    return apolloProvider.query({
      query: queries.GET_COMPANY_WALLETS_COUNT,
      variables: {
        ...data,
      },
    })
  },
  getCompanyWalletShares(data) {
    return apolloProvider.query({
      query: queries.GET_COMPANY_WALLET_SHARES,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
  },
  makeCompanyAltFamilyTransfer(data) {
    return apolloProvider.mutate({
      mutation: mutation.MAKE_COMPANY_ALT_FAMILY_TRANSFER,
      variables: {
        ...data,
      },
    })
  },
  getCompanyWalletFamilies(data) {
    return apolloProvider.query({
      query: queries.GET_COMPANY_WALLET_FAMILIES,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
  },
  updateCompanyWalletDetails(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_COMPANY_FAMILY_DETAIL,
      variables: {
        ...data,
      },
    })
  },
  inviteForCompanyWalletShare(data) {
    return apolloProvider.mutate({
      mutation: mutation.COMPANY_WALLET_SHARE_INVITE,
      variables: {
        ...data,
      },
    })
  },
  revokeCompanyWalletShareInvite(data) {
    return apolloProvider.mutate({
      mutation: mutation.REVOKE_COMPANY_WALLET_SHARE_INVITE,
      variables: {
        ...data,
      },
    })
  },
  revokeCompanyWalletShare(data) {
    return apolloProvider.mutate({
      mutation: mutation.REVOKE_COMPANY_WALLET_SHARE,
      variables: {
        ...data,
      },
    })
  },
  getCompanyScheduledTransfers(data) {
    return apolloProvider.query({
      query: queries.GET_COMPANY_SCHEDULED_TRANSFERS,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
  },
  createCompanyWalletTransferSchedule(data) {
    return apolloProvider.mutate({
      mutation: mutation.CREATE_COMPANY_WALLET_TRANSFER_SCHEDULE,
      variables: {
        ...data,
      },
    })
  },
  updateCompanyWalletTransferSchedule(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_COMPANY_WALLET_TRANSFER_SCHEDULE,
      variables: {
        ...data,
      },
    })
  },
  activateCompanyWalletTransferSchedule(data) {
    return apolloProvider.mutate({
      mutation: mutation.ACTIVATE_COMPANY_WALLET_TRANSFER_SCHEDULE,
      variables: {
        ...data,
      },
    })
  },
  makeCompanyReconciliationTransaction(data) {
    return apolloProvider.mutate({
      mutation: mutation.MAKE_COMPANY_RECONCILIATION_TRANSACTION,
      variables: {
        ...data,
      },
    })
  },
  deactivateCompanyWalletShare(data) {
    return apolloProvider.mutate({
      mutation: mutation.DEACTIVATE_OPEN_WALLET,
      variables: { ...data },
    })
  },
  addNewCompanyWalletShares(data) {
    return apolloProvider.mutate({
      mutation: mutation.ADD_NEW_OPEN_WALLET,
      variables: { ...data },
    })
  },
  getCompanyPayrollWallets(data) {
    return apolloProvider.query({
      query: queries.GET_COMPANY_PAYROLL_WALLETS,
      variables: { ...data },
      fetchPolicy: 'no-cache',
    })
  },
  updateCompanyReconciliationTransaction(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_COMPANY_RECONCILIATION_TRANSACTION,
      variables: { ...data },
    })
  },
  editCompanyTransactionAllocation(data) {
    return apolloProvider.mutate({
      mutation: mutation.EDIT_COMPANY_TRANSACTION_ALLOCATION,
      variables: { ...data },
    })
  },
  updateCompanyWalletOrders(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_COMPANY_WALLET_ORDERS,
      variables: { ...data },
    })
  },
  updateCompanyWalletShareOrders(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_COMPANY_WALLET_SHARE_ORDERS,
      variables: { ...data },
    })
  },
  deleteCompanyTransferTransaction(data) {
    return apolloProvider.mutate({
      mutation: mutation.DELETE_COMPANY_TRANSFER_TRANSACTION,
      variables: { ...data },
    })
  },
  getCompanyAltWallets(data) {
    return apolloProvider.query({
      query: queries.GET_COMPANY_ALT_WALLETS,
      fetchPolicy: 'no-cache',
      variables: { ...data },
    })
  },
  createNewCompanyVendor(data) {
    return apolloProvider.mutate({
      mutation: mutation.CREATE_COMPANY_VENDOR,
      variables: { ...data },
    })
  },
  updateProjectCloudSettings(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_PROJECT_CLOUD_SETTINGS,
      variables: { ...data },
    })
  },
  getProjectSettings(data) {
    return apolloProvider.query({
      query: queries.GET_PROJECT_SETTINGS,
      variables: { ...data },
      fetchPolicy: 'no-cache',
    })
  },
  cancelUserPayment(data) {
    return apolloProvider.mutate({
      mutation: mutation.CANCEL_USER_PAYMENT,
      variables: { ...data },
    })
  },
  getCompanyCampaigns(data) {
    return apolloProvider.query({
      query: queries.GET_COMPANY_CAMPAIGNS,
      variables: { ...data },
      fetchPolicy: 'no-cache',
    })
  },
  createCampaign(data) {
    return apolloProvider.mutate({
      mutation: mutation.CREATE_SOCIAL_CAMPAIGN,
      variables: { ...data },
    })
  },
  updateCampaign(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_SOCIAL_CAMPAIGN,
      variables: { ...data },
    })
  },
  deleteCampaign(data) {
    return apolloProvider.mutate({
      mutation: mutation.DELETE_SOCIAL_CAMPAIGN,
      variables: { ...data },
    })
  },
  createCampaignGeneration(data) {
    return apolloProvider.mutate({
      mutation: mutation.CREATE_CAMPAIGN_GENERATION,
      variables: { ...data },
    })
  },

  updateCampaignGeneration(data) {
    return apolloProvider.mutate({
      mutation: mutation.UPDATE_CAMPAIGN_GENERATION,
      variables: { ...data },
    })
  },
  getCampaignProgressBatch(data) {
    return apolloProvider.query({
      query: queries.GET_CAMPAIGN_PROGRESS_BATCH,
      variables: { ...data },
      fetchPolicy: 'no-cache',
    })
  },
  addCampaignFacebookSettings(data) {
    return apolloProvider.mutate({
      mutation: mutation.ADD_CAMPAIGN_FACEBOOK_SETTINGS,
      variables: { ...data },
    })
  },
  // getCampaignProgressByKey(data) {
  //   return apolloProvider.query({
  //     query: queries.GET_CAMPAIGN_PROGRESS_BY_KEY,
  //     variables: { ...data },
  //     fetchPolicy: 'no-cache',
  //   })
  // },
  // getCampaignProgress() {
  //   return apolloProvider.query({
  //     query: queries.GET_CAMPAIGN_PROGRESS,
  //     fetchPolicy: 'no-cache',
  //   })
  // },
}
