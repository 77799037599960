<template>
  <div
    id="app"
    :class="[skinClasses, calling && roomUid ? 'overflow-hidden' : '']"
  >
    <b-overlay
      id="tour-overlay"
      :show="true"
    >
      <component :is="layout" />
    </b-overlay>

    <floating-chat v-if="$store.state.project.selectedProject" />
    <div
      v-if="calling && roomUid && activeChat"
      id="mediasoup-demo-app-container"
    >
      <room
        :room-uid="roomUid"
        :peer="peer"
        :room-client="roomClient"
        :active-chat="activeChat"
      />
    </div>
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import {
  watch, inject, ref,
} from 'vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BButton, BOverlay } from 'bootstrap-vue'
import { useWindowSize, useCssVar } from '@vueuse/core'
import store from '@/store'
import FloatingChat from '@/views/chat/FloatingChat.vue'
import { ChatEvents } from 'workzone-chat-sdk'
import { getUserData } from './auth/utils'
import Room from './chat/components/Room.vue'
import EventBus from './event-bus'
import 'driver.js/dist/driver.css'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    FloatingChat,
    BButton,
    BOverlay,
    Room,
  },

  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'

      return `layout-${this.contentLayoutType}`
    },

    role() {
      return localStorage.getItem('projectUserRole')
    },

    userData() {
      return getUserData()
    },

    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const chatService = inject('$chatService')
    const { skin, skinClasses } = useAppConfig()
    const calling = ref(false)
    const roomUid = ref(null)
    const peer = ref(null)
    const roomClient = ref(null)
    const activeChat = ref(null)
    const showTour = ref(false)

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    // Chat Events
    chatService.on(ChatEvents.ChatAuthSuccessEvent, data => {
      const { user } = data
      chatService.joinRoom(user.uuid)
    })

    const closeCallWindow = () => {
      calling.value = false
      roomUid.value = null
    }

    // Close the call window when the call is declined or ended
    EventBus.$on('call-ended', closeCallWindow)

    // Set peer and show the call window, when user calls
    const setupCallWindow = (contact, _client) => {
      roomClient.value = _client
      calling.value = true
      peer.value = {
        ...contact,
        displayName: contact.name,
      }
      roomUid.value = contact.roomUid
    }

    // Call
    EventBus.$on('outgoing', (chat, _client) => {
      activeChat.value = chat
      setupCallWindow(chat.contact, _client)
    })
    EventBus.$on('incoming', (contact, _client) => {
      store.dispatch('chat/getChatContact', contact.roomUid).then(response => {
        activeChat.value = response
      })
      setupCallWindow(contact, _client)
    })

    return {
      skinClasses,

      calling,
      roomUid,
      peer,
      roomClient,
      activeChat,
      showTour,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.dropdown-menu.show {
  z-index: 1000 !important;
}

#tour-overlay > .b-overlay {
  display: none;
}
</style>
