// eslint-disable-next-line import/no-duplicates
import Vue from 'vue'
import { ToastPlugin, ModalPlugin, VBTooltipPlugin } from 'bootstrap-vue'

import toastMixin from '@/utils/toast'
import commonMixin from '@/utils/common'
import strings from '@/utils/strings'
import cachedFilters from '@/utils/cachedFilters'
import VueSweetalert2 from 'vue-sweetalert2'
import AVPlugin from 'vue-audio-visual'

import router from './router'
import store from './store'
import App from './App.vue'
import COMMON from './utils/sharedData'

import firebaseMessaging from './firebase'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

// Chat SDK
import { ChatService } from 'workzone-chat-sdk'
import i18n from './libs/i18n'

const token = localStorage.getItem('accessToken')
const chatService = ChatService.init(process.env.VUE_APP_CHAT_WSS_URL, `${token}`, process.env.VUE_APP_CHAT_MSA_CLIENT_ID)
Vue.prototype.$chatService = chatService

Vue.prototype.$messaging = firebaseMessaging
// document.body.style.zoom = '90%'
require('@core/scss/vue/libs/vue-select.scss')
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VBTooltipPlugin)
Vue.use(AVPlugin)

// Composition API

Vue.use(VueSweetalert2)
// import core styles
require('@core/scss/core.scss')

// import assets styles
// require('@/assets/scss/style.scss')
// import stlyes for vue-good-table
require('@core/scss/vue/libs/vue-good-table.scss')

Vue.config.productionTip = false

if (['dev', 'release'].includes(process.env.VUE_APP_ENV)) {
  Vue.config.devtools = true
}

i18n.locale = localStorage.getItem('locale')
if (!i18n.locale) {
  i18n.locale = 'en'
}

Vue.mixin({
  data() {
    return {
      get COMMON() {
        return COMMON
      },
    }
  },
  methods: {
    SHOW_RECORD(date, userId) {
      router.push({
        name: 'home',
        query: { date, userId },
      })
    },
    ...toastMixin,
    ...commonMixin,
    ...cachedFilters,
    ...strings,
  },
})
// eslint-disable-next-line import/prefer-default-export
export const vm = new Vue({
  store,
  i18n,
  router,
  provide: {
    $chatService: chatService,
  },
  render: h => h(App),
}).$mount('#app')
