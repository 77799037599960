import moment from 'moment'

export default {
  namespaced: true,
  state: {
    campaignEvents: [],
    posts: [],
  },
  getters: {
    getCampaignEvents: state => state.campaignEvents,
    getCampaignPosts: state => state.posts,
  },
  mutations: {
    INITIALIZE_CAMPAIGN_EVENTS: (state, payload) => { state.campaignEvents = payload },
    UPDATE_CAMPAIGN_EVENT(state, payload) {
      const campaign = state.campaignEvents.find(event => event.uuid === payload.uuid)
      const index = state.campaignEvents.indexOf(campaign)
      state.campaignEvents.splice(index, 1, payload)
    },
    UPDATE_CAMPAIGN_POSTS(state, { campaignUid, posts }) {
      state.posts.push(...posts)
      const latestDate = posts
        .map(post => moment(post.date).add(1, 'days').toDate())
        .sort((a, b) => b - a)[0]
      const campaign = state.campaignEvents.find(event => event.uuid === campaignUid)

      campaign.color = moment(latestDate).isSameOrAfter(moment(), 'date') ? 'green' : 'red'
      campaign.end = latestDate
    },
    UPDATE_A_CAMPAIGN_POST(state, { postUid, payload }) {
      const updatedPost = state.posts.find(post => post.uuid === postUid)
      const index = state.posts.indexOf(updatedPost)
      state.posts[index] = {
        ...state.posts[index],
        ...payload,
      }
    },
    CLEAR_PREV_CAMPAIGN_POSTS(state) {
      state.posts = []
    },
  },
  actions: {
    initalizeCampaignEvents({ commit }, data) {
      commit('INITIALIZE_CAMPAIGN_EVENTS', data)
    },
    updateCampaignEvent({ commit }, data) {
      commit('UPDATE_CAMPAIGN_EVENT', data)
    },
    updateSingleCampaignPost({ commit }, data) {
      commit('UPDATE_A_CAMPAIGN_POST', data)
    },
    updateCampaignPosts({ commit }, data) {
      commit('UPDATE_CAMPAIGN_POSTS', data)
    },
    clearPrevCampaignPosts({ commit }) {
      commit('CLEAR_PREV_CAMPAIGN_POSTS')
    },
  },
}
